.container {
    position: absolute;
    /* top: 10px; */
    /* margin:auto; */
    /* padding: 20px; */
    width: 100%;
    height: 70vh;
    margin-left: auto;
    margin-right: auto;
  }

.container img {
  height: 70vh;
  margin-left: auto;
  margin-right: auto;
}

.artwork_title {
  /* position: fixed; */
  padding-left: 6vw;
  /* top: 520px;  */
}

.artwork_title h4 {
  margin-bottom: 0 !important;
  margin-top: 2px;
}
  
h3 {
  /* background: blue; */
  color: #fff;
  font-size: 36px;
  line-height: 100px;
  margin: 10px;
  padding: 2%;
  position: relative;
  text-align: center;
}
.variable-width .slick-slide p {
  /* background: blue; */
  height: 100px;
  color: #fff;
  margin: 5px;
  line-height: 100px;
  text-align: center;
}
.center .slick-center h3 {
  color: #e67e22;
  opacity: 1;
  transform: scale(1.08);
}
.center h3 {
  opacity: 0.8;
  transition: all 300ms ease;
}
.content {
  padding: 20px;
  margin: auto;
  width: 100%;
}
.slick-slide .image {
  padding: 10px;
}
.slick-slide img {
  /* border: 5px solid #fff; */
  display: block;
  margin: auto;
  box-shadow: #fff;
}
.slick-slide img.slick-loading {
  border: 0;
}
.slick-slide {
  padding: 0;
}
.slick-slider {
  margin: 30px auto 50px;
}
@media (max-width: 500px) {
  .slick-slider {
    margin: 20px auto 50px;
  }
}

.slick-dots {
  margin-left: 0;
}
.slick-thumb {
  bottom: -45px;
}
.slick-thumb li {
  width: 60px;
  height: 45px;
}
.slick-thumb li img {
  filter: grayscale(100%);
}
.slick-thumb li.slick-active img {
  filter: grayscale(0%);
}
@media (max-width: 768px) {
  h3 {
    font-size: 24px;
  }
  .center {
    margin-left: -40px;
    margin-right: -40px;
  }
  .center .slick-center h3 {
    color: #e67e22;
    opacity: 1;
    transform: scale(1);
  }
  .center h3 {
    opacity: 0.8;
    transform: scale(0.95);
    transition: all 300ms ease;
  }
  .artwork_title {
    top: 75vh; 
  }
}

.slick-vertical .slick-slide {
  height: 180px;
}
.slick-arrow {
  /* background-color: grey; */
  height: 40px;
  -webkit-filter: drop-shadow( 3px 3px 2px blue);
  filter: drop-shadow( 3px 3px 2px blue);
  /* top: 300px; */
  /* transition: transform .2s; */

}
/* .slick-arrow:hover {
  height: 60px;
} */
.slick-prev {
  z-index: 100;
  left: 20px !important;
  top: 40%;
}
.slick-next {
  z-index: 100;
  right: 20px !important;
  top: 40%;
}
