.base-audio {
  position: absolute;
  bottom: 60px;
  left: 80px;
  color: white;
  /* width: 30px;
  height: 30px; */
  background-image: url("./../../images/volume.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.audio-controls {
  position: absolute;
  bottom: 25px;
  right: 35px;
  /* display: flex;
  justify-content: space-between; */
  /* width:  75%; */
  margin: 0 auto 15px;
  z-index: 1;
}

.audio-controls .play svg,
.audio-controls .pause svg {
  height: 35px;
  width: 35px;
  -webkit-filter: drop-shadow( 3px 3px 2px blue);
  filter: drop-shadow( 3px 3px 2px blue);
}

.audio-controls path {
  fill: white;
}

button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
}
