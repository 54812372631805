/* @font-face {
    font-family: 'Proba Pro Regular';
    src: url('./fonts/ProbaPro-Regular.eot');
    src: url('./fonts/ProbaPro-Regular.eot?#iefix') format('embedded-opentype'),
    url('./fonts/ProbaPro-Regular.woff') format('woff'),
    url('./fonts/ProbaPro-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    } */

.opacityWrapper{                        
    animation-name: opacitychange;
    animation-duration: 3s;
    animation-timing-duration: linear;                
    -webkit-animation-name: opacitychange;
    -webkit-animation-duration: 3s;
    -webkit-animation-timing-duration: linear;
  }
  
  @keyframes opacitychange{
  from { filter: opacity(0%); }
  to { filter: opacity(100%); }
  } 
                       
#bodyWrapper {
    display: none;
}

html {
	height: 100%;
    scroll-behavior: smooth;
    -webkit-font-smoothing: antialiased;
}

body {

    letter-spacing: 0.5px;
    color: white;
    background: #1d1d1d;
    height: 100%;
    width: 100%;
    overflow-x: hidden !important;
}

canvas {
    width: 100vw;
}

body::-webkit-scrollbar {
    width: 13px;
    background-color: grey;
}

body::-webkit-scrollbar-thumb {
    background: black;
    -webkit-border-radius: 0;
    border-radius: 0
}

h1 {
    font-size: 26px;
    text-align: center;
    margin-bottom: 40px;
}

#content {
    color: white;
    padding: 55px 0 100px 0;
    min-height: 100%;
}

.carousel-container {
    padding-bottom: 20px;
}

/* Заголовок для фильмов */
.header_film {
	color: white;
    font-size: 24px;
    font-weight: 300;
    text-align: left;
    margin: 10px 0 0 35px;
}

.header_film a {
	color: white;
}

.header_film a:hover {
    color: #CBA903;
    text-decoration: none;
	font-size: 24px;
	text-align: center;
	margin: 20px 0 30px 0;
}

.header_film a i {
    position: relative;
    top: 2px;
}

.header_film a:hover i {
    margin-left: 10px;
    transition: all 0.5s;
}

/* Заголовок страницы */
.header_page {
    color: white;
    font-size: 24px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 30px;
}

/* Индикатор подписки */
.subscription p {
    color: grey;
    font-size: 14px;
    padding: 0;
    margin: 0;
}

.subscription div {
    background: green;
    height: 3px;
    margin-top: 5px;
}

.fa-star {
    color: orange;
}

/* Личный кабинет */
.profile {
    color: white;
    padding: 20px 0 20px 0;
}

.changeAva {
    margin: 40px auto 0;
    max-width: 500px;
}

.changeAva img {
    width: 200px;
    height: 200px;
}

.account {
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.avatar, .account img {
    height: 45px;
    width: 45px;
}

.account .dropdown-menu {
    width: 200px !important;
    min-height: 335px !important;
    padding: 5px;
    left: -55px !important;
}

.account ul {
    padding: 0;
}

.account a {
    font-size: 14px;
}

/* Настройки контента */
#content h1 {
	margin-top: 10px;
}

#content span {
	color: #9a9a9a;
    font-weight: bold;
}

#content span .lds-css {
    height: 300px;
}

/* Подписки */
.subscribe {
    color: white;
}

.rate {
    background: black;
    padding: 20px;
    border: 1px solid white;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 200px;
}

.rate:hover {
    background: white;
    color: black;
}

.rate:hover .rate-btn {
    background: #ffca28;
    color: black;
}

.rate:hover .rate-ratio {
    color: gray;
}

.rate-month {
    font-size: 24px;
    margin-bottom: 15px;
    font-weight: 400;
}

.rate-days {
    font-size: 14px;
    line-height: 1;
    color: #717171;
    margin-bottom: 28px;
}

.rate-info {
    color: #fff;
    background-color: #24b2d8;
    margin-bottom: 14px;
    padding: 5px;
    border-radius: 5px;
    font-size: 14px;
}

.rate-price {
    font-size: 48px;
    font-weight: 500;
    margin-bottom: 30px;
}

.rate-ratio {
    font-size: 16px;
    line-height: 1;
    color: white;
    margin-bottom: 28px;
}

.rate-btn {
    font-size: 18px;
    font-weight: 400;
    line-height: 1;
    padding: 14px 22px 13px;
    color: white;
    border: 1px solid gray;
}

.rate-btn:hover {
    color: #171717;
    background-color: #daa500 !important;
}

/* Уберем обводку у кнопок */
button:active, button:focus { 
    outline: none !important; 
}    

/* Корректируем бутстрап */
.btn-outline-secondary {
    border-color: white;
}

.btn-outline-secondary:hover {
    background: white;
    color: black;
    border-color: white;
}

/* Ссылка на страницу с фильмами */
.films-link {
    border: 1px solid #333;
    padding: 5px;
    width: 50px;
    background-color: rgba(255,255,255,.15);
    float: right;
    position: relative;
    right: 120px;
    top: -33px;
    clear: both;
    border-radius: 5px;
    color: white;
    font-size: 16px;
    text-align: center;
}

.films-link:hover {
    color: white;
    background: #848586;
    text-decoration: none;
}

/*Подвал*/
footer {
    color: white;
    padding: 25px 0 15px 0;
    background: rgba(0, 0, 0, 0.95);
}

.footer-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.footer-text {
    color: white;
    font-size: 14px;
    text-align: center;
    margin-bottom: 10px;
}

.genre {
    position: relative;
    width: 100%;
    margin: 15px 0 15px 0;
}

.genre img {
    display: block;
    margin: 0 auto;
    width: 100%;
    height: 100px;
    border-radius: 5px;
}

.genre .genre-after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: #FFF;
    display: block;
    background: rgba(0, 0, 0, .5);
}

.genre .genre-after a {
    display: block;
    height: 200px;
    color: white;
    font-size: 20px;
    text-align: center;
    line-height: 100px;
}

.genre .genre-after a:hover {
    text-decoration: none;
}

/* Формы регистрации */
.form-check-input {
    margin-top: 6px;
}

.registration h3 {
    text-align: center;
    font-size: 24px;
    margin: 10px 0 20px 0;
}

.registration a {
    color: white;
}

/* Обрезаем текст */
.text-ellipsis {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}

/* Реакт слайдер */
.slick-slide {
    padding: 0 10px 0 10px;
}

.slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.slick-dots {
    display: none !important;
}

.carousel .slick-arrow {
    border: none;
    padding: 0 7px;
    width: 40px;
    height: 300px;
    background-color: rgba(0, 0, 0, .5);
    position: absolute;
    z-index: 999;
}

.carousel .slick-arrow:hover {
    opacity: 0.8 !important;
}

.carousel .slick-list {
    padding-top: 20px;
    clear: both;
    height: 380px;
    position: relative;
}

.carousel .slick-arrow.slick-prev {
    opacity: 0.6;
    clear: both;
    top: 34px !important;
    left: 0px !important;
    display: inline-block !important;
    color: #fff;
    border-radius: 0px 7px 7px 0px
}

.carousel .slick-arrow.slick-next {
    opacity: 0.6;
    clear: both;
    right: 0px !important;
    top: 34px !important;
    display: inline-block !important;
    color: #fff;
    border-radius: 7px 0px 0px 7px
} 

/* @media (max-width: 530px){
    .slick-arrow.slick-prev {
        bottom: 50vh !important;
    }
} */




/* Политика приватности */
.private h3 {
    text-align: center;
    font-size: 20px;
}

/* Реактовские вкладки */
.react-tabs__tab-list {
    list-style-type: none;
    display: block;
    width: 500px;
    margin: 0 auto;
    position: relative;
    top: 12px;
    color: #888;
}

.react-tabs__tab-list li {
    display: inline-block;
    padding-bottom: 5px;
    margin-left: 15px;
}

.react-tabs__tab-list li:hover{
    cursor: pointer;
    color: #c7c7c7;
}


.react-tabs__tab--selected {
    border-bottom: 1px solid red;
    cursor: pointer;
    color: #fff;
}

/* Вкладки */
/* Реактовские вкладки */
#seasons .react-tabs__tab-list {
    list-style-type: none !important;
    float: left;
    width: 500px;
    position: relative;
    top: 0px;
    padding-left: 0 !important;
    clear: both;
}

#seasons .react-tabs__tab-list li {
    display: inline-block;
    padding-bottom: 10px;
    margin-left: 15px;
}

#seasons .react-tabs__tab-list li:hover,
#seasons .react-tabs__tab--selected {
    border-bottom: 1px solid yellow !important;
    cursor: pointer;
}

.carousel-screen button {
    display: none !important;
    visibility: hidden;
}

.container{
	position: relative;
	overflow: hidden;
}

.videoContainer{
	position: absolute;
	left:0;
	top:0;
	right:0;
	bottom:0;
	width:100%;
	height:100%;
	z-index: 0;

}

.overlay{
	position: absolute;
	left:0;
	top:0;
	right:0;
	bottom:0;
	z-index: 5
}
.videoIframe{
	position: relative;
	left:0;
	top:0;
	right:0;
	bottom:0;
	width: 100%;
	height: 100%;
	z-index:0;

}
