@font-face {
  font-family: 'Proba Pro Regular';
  src: url('./fonts/ProbaPro-Regular.eot');
  src: url('./fonts/ProbaPro-Regular.eot?#iefix') format('embedded-opentype'),
  url('./fonts/ProbaPro-Regular.woff') format('woff'),
  url('./fonts/ProbaPro-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  }


body, html {
  background-color: white;
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

body canvas {
  /* position: fixed; */
  /* top: 0; */
  height: 100vh !important;
}

.artist_name {
  display: flex;
  justify-content: center;
  font-family: 'Proba Pro Regular';
  text-shadow: 0 0 1em blue, 0 0 0.2em blue;
  /* font-family: sans-serif; */
  letter-spacing: 1.1em;
  color: white;
  position: absolute;
  width: 100%;
  height: 115px;
  top: 0;
  text-align: center;
  z-index: 1;
  font-style: normal;
  font-weight: 200;
  font-size: 40px;
  line-height: 120px; 
  align-items: center;
}

@media(max-width: 770px) {
  .artist_name {
    top: 25px;

  }
}
@media(max-width: 500px) {
  .artist_name {
    top: 25px;
    font-size: 30px;
  }
}

.link {
  display: flex;
  justify-content: center;
  color: #FFF;
  text-decoration: none;
  margin-left: 30px;
}
.link:hover {
  color: #00F
}

.buttonBack {
  position: absolute;
  top: 15vh;
  left: 5vw;
  z-index:10;
  font-size: 30px;
  cursor: pointer;
}

.site_link {
  font-family: 'Proba Pro Regular';
  /* font-family: sans-serif; */
  letter-spacing: 1.1em;
  color: white;
  position: absolute;
  width: 100%;
  top: 60px;
  text-align: center;
  z-index: 1;
  /* font-style: normal; */
  font-weight: 200;
  font-size: 30px;
  line-height: 120px; 

}

@media(max-width: 770px) {
  .site_link {
    top: 100px;

  }
}
@media(max-width: 500px) {
  .site_link {
    top: 100px;
    font-size: 20px;
  }
}

body::-webkit-scrollbar {
  width: 0px;
  background-color: grey;
  opacity: 0;
}

body::-webkit-scrollbar-thumb {
  background: black;
  -webkit-border-radius: 0;
  border-radius: 0
}


.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

#background-video {
  height: 100%;
  width: 100%;
  float: left;
  top: 0;
  padding: none;
  position: fixed; /* optional depending on what you want to do in your app */
  z-index: 0;
}

.player-wrapper {
  position: relative;
  padding-top: 56.25% /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.container {
  top: 50% !important;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.main-container {
  width: 100vw;
  height: 100vh;
}

.soundcloud-container {
  width: 100vw;
  top: 30vh;
  margin: auto;
  position: absolute;
  display: flex;
  justify-content: center;
  justify-items: center;
  justify-self: center;
}

@media(max-width: 500px) {
  .soundcloud-container {
    top: 23vh;
  }
}

.buttons_row {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 6vh;
}

.buttons_row button {
  background-color: Transparent;
  color: white;
  border: none;
  font-weight: bold;
  cursor: pointer;
}

.footer {
  position: absolute;
  bottom: 25px;
  left: 30px;
  margin: 0 auto 15px;
  z-index: 1;
  font-family: 'Proba Pro Regular';
  text-shadow: 0 0 1em blue, 0 0 0.2em blue;
  font-weight: 200;
  font-size: 20px;
}




