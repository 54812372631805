@media (min-width: 993px) {
    .genre {
        display: none;
    }
    
    .dropdown-menu {
        padding-top: 35px;
        width: 700px;
        height: 140px;
    }

    .responsive_link {
        display: none;
    }

    /* Ссылки в главном меню */
    .mobile-link {
        display: none;
    }

    .personal-header {
        font-size: 20px;
        margin-top: 15px;
    }
}

@media (max-width: 992px) {
    .genre-sliders {
        display: none;
    }

    h1 {
        margin-bottom: 0;
        font-size: 22px;
    }

    #wrapper, #content {
        padding: 0 0 15px 0px;
    }

    .rate {
        background: white;
        color: black;
    }

    .rate .rate-btn {
        background: #ffca28;
        color: black;
    }
    
    .rate .rate-ratio {
        color: gray;
    }

    /* Страница плеера */
    .player {
        padding: 0;
        height: 600px;
    }

    /* Ссылки в главном меню */
    .pc-link {
        display: none;
    }
    /* Подписка */
    .rate {
        margin: 30px auto 0;
    }
}

@media (max-width: 530px) {
    .carousel-header {
        font-size: 22px;
    }

    .carousel-screen .slick-arrow.slick-next {
        bottom: 277px;
    }

    .slick-arrow.slick-prev {
        /* top: -25px; */
        right: 50px;
        top: 40%;
    }

    .slick-arrow.slick-next {
        right: 11px;
        bottom: 422px;
        top: 40%;
    }

    .recFilms .slick-arrow.slick-next {
        bottom: 357px;
    }

    .recFilms .slick-list {
        height: 300px;
    }

    .films-link {
        display: none;
    }

    .header_film a:hover i {
        margin-left: 0;
    }

    .smallfilm-name, .bigfilm-name {
        font-size: 12px;
    }
}

@media (max-width: 486px) {
    .header_film {
        font-size: 18px;
        display: block;
        margin-left: 10px;
    }

    .header_film a:hover {
        font-size: 18px;
    }

    .films-link {
        display: none;
    }
}

.personal {
    background: rgba(0, 0, 0, .5);
}

.personal-list {
    margin-top: 30px;
}

.personal-list li {
    border-top: 1px solid gray;
    padding: 10px 0 10px 0;
}

.personal-list li:last-child {
    border-bottom: 1px solid gray;
}

.personal-list li a {
    color: #F5F5F5;
    padding: 10px 0 10px 0;
    display: block;
    font-size: 16px;
    font-weight: 300;
}

.personal-list li a:hover {
    text-decoration: none;
}

/* Иконка в подменю */
.personal img {
    height: 40px;
    width: 40px;
    float: left;
    position: relative;
    top: 10px;
}

.personal .username {
    position: relative;
    top: 20px;
    left: 30px;
    font-size: 20px;
    color: white;
}

.personal .useremail {
    clear: both;
    position: relative;
    top: 20px;
    font-size: 20px;
    color: white;
}